import React, { Suspense, useEffect, useState } from "react";
import {
  KontaktWrapper,
  LinkWrapper,
  MainWrapper,
  NameWrapper,
  Sektion,
  SektionsWrapper,
  SideSegment,
  WelcomeWrapper,
} from "./style/style";
import { Logo } from "./components/logo";
import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FiInstagram, FiFacebook, FiYoutube } from "react-icons/fi";

const client = createClient({
  space: "jf9bkrjj5kmq",
  accessToken: "Uq8wq4RMrmyGuzFwrUoqjEJQ5AvBTwTGmbsaNF5Eoh0", // Delivery API Token
  host: "cdn.contentful.com", //
});

function App() {
  const [page, setPage] = useState<Array<any>>([]);
  const [sections, setSections] = useState<Array<any>>([]);
  const [stysse, setStysse] = useState<Array<any>>([]);
  const StringBuilderAnimation = ({ text, startDelay }: any) => {
    //@ts-ignore
    return text.split("").map(function (char, index) {
      const style = { animationDelay: startDelay + index / 10 + "s" };
      return (
        <span aria-hidden="true" key={index} style={style}>
          {char.toUpperCase()}
        </span>
      );
    });
  };

  const _ScrollIntoView = (id: string) => {
    //@ts-ignore
    document
      .getElementById(id)
      .scrollIntoView({ behavior: "smooth", inline: "nearest" });
  };
  useEffect(() => {
    const getContent = async () => {
      const entries = await client.getEntries({
        content_type: "frontContent",
      });
      const sektioner = await client.getEntries({
        content_type: "sektion",
      });
      const styssereq = await client.getEntries({
        content_type: "kontakt",
      });

      setPage(
        entries.items.sort(
          // @ts-ignore
          (a, b) => new Date(a.sys.createdAt) - new Date(b.sys.createdAt)
        )
      );
      setSections(sektioner.items.sort((a, b) => 0.5 - Math.random()));
      setStysse(styssereq.items);
    };
    getContent();
  }, []);

  return (
    <React.Fragment>
      <MainWrapper>
        <NameWrapper>
          <h1 style={{ marginBottom: 4 }}>
            <StringBuilderAnimation text="BLÅSLAGET" startDelay={1} />
          </h1>
          <h2>
            <StringBuilderAnimation text="OCH" startDelay={1} />
          </h2>
          <h1 style={{ marginBottom: 0, marginTop: 4 }}>
            <StringBuilderAnimation text="BALETTEN" startDelay={1} />
          </h1>
          <h1 style={{ marginTop: 0 }}>
            <StringBuilderAnimation text="DRAGPLÅSTRET" startDelay={1} />
          </h1>
        </NameWrapper>
        <Logo />
        <LinkWrapper>
          <button onClick={() => _ScrollIntoView("omoss")}>
            <StringBuilderAnimation text="Om oss" startDelay={1} />
          </button>

          <button onClick={() => _ScrollIntoView("gamed")}>
            <StringBuilderAnimation text="Gå med oss!" startDelay={1} />
          </button>
          <button onClick={() => _ScrollIntoView("bokaoss")}>
            <StringBuilderAnimation text="Boka Oss" startDelay={1} />
          </button>
          <button onClick={() => _ScrollIntoView("kontakt")}>
            <StringBuilderAnimation text="kontakt" startDelay={1} />
          </button>
        </LinkWrapper>
      </MainWrapper>
      <WelcomeWrapper id="omoss">
        <h1>Om oss</h1>
        <Suspense fallback={<h1>Spelar och dansar in text och bild</h1>}>
          {page.map((content: any, index) => {
            return (
              <div key={index} id={content?.fields.id}>
                <SideSegment>
                  <img
                    src={`https://${content.fields.contentImage.fields.file.url}`}
                    alt={content.fields.contentImage.fields.description}
                  ></img>
                  <div>
                    <h2>{content?.fields.title}</h2>
                    {documentToReactComponents(content.fields.content)}
                  </div>
                </SideSegment>
                <SektionsWrapper>
                  {content.fields.title === "Orkestern"
                    ? sections.map((section, index) => {
                        return (
                          <Sektion key={index}>
                            <h4>{section.fields.title}</h4>
                            <img
                              src={`https://${section.fields.sectionImage.fields.file.url}`}
                              alt={
                                section.fields.sectionImage.fields.description
                              }
                            ></img>
                          </Sektion>
                        );
                      })
                    : ""}
                </SektionsWrapper>
              </div>
            );
          })}
        </Suspense>
      </WelcomeWrapper>
      <WelcomeWrapper id="kontakt">
        <h1>Kontakt</h1>
        <KontaktWrapper>
          {stysse.map((img, index) => {
            return (
              <img
                key={index}
                src={`https://${img.fields.stysse.fields.file.url}`}
                alt={img.fields.stysse.fields.description}
              ></img>
            );
          })}

          <div>
            <h2>Oss kan ni hitta på:</h2>

            <a
              href="https://www.instagram.com/blaslaget"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
              <FiInstagram />
            </a>
            <a
              href="https://www.facebook.com/blaslaget"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
              <FiFacebook />
            </a>
            <a
              href="https://www.youtube.com/user/Blaslaget"
              target="_blank"
              rel="noreferrer"
            >
              Youtube <FiYoutube />{" "}
            </a>
            <p>Eller maila: ordf@blaslaget.se</p>
          </div>
        </KontaktWrapper>
      </WelcomeWrapper>
    </React.Fragment>
  );
}

export default App;
