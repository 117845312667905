import React from 'react';
import { createGlobalStyle } from 'styled-components';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const GlobalStyle = createGlobalStyle`
    html,#__next{
        
        
    }
    
    body {
    margin: 0;
    padding: 0px;
    height: 100%;
    width: 100%;
    font-family: 'Roboto Mono', monospace;
    color: white;
    background-color: black;
    scroll-behavior: smooth;
    }
  ::-webkit-scrollbar {
    width: 10px;
  }

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0.4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255,255,255,0.6);
}
`;

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyle />
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
