import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  height: 100vh;
  @media screen and (max-width: 880px) {
    flex-direction: column;
    svg {
      width: auto;
      height: 200px;
    }
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  h1 {
    text-align: center;
    font-size: 2rem;
    color: yellow;
    width: 40%;
    text-align: center;
  }
  span {
    position: relative;
    opacity: 0;
    animation: move-text 0.5s forwards;
  }
  h2 {
    margin: 0;
    color: yellow;
  }
  h1,
  h2 {
    width: fit-content;
  }

  @keyframes move-text {
    0% {
      left: -1rem;
      opacity: 0;
    }
    50% {
      left: 0rem;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  button {
    text-decoration: none;
    color: white;
    font-size: 1.4rem;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    cursor: pointer;
    span {
      position: relative;
      opacity: 0;
      animation: move-text 1s forwards;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  @keyframes move-text {
    0% {
      left: -1rem;
      opacity: 0;
    }
    50% {
      left: 0rem;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
`;

export const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 2rem;
  h1 {
    border-bottom: 1px solid white;
    font-size: 3rem;
  }
`;

export const SideSegment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  img {
    max-width: 60%;
    height: auto;
    border-radius: 10%;
  }
  div {
    font-size: 1.4rem;
    padding: 1rem;
  }
  h2 {
    border-bottom: 1px solid white;
  }
  @media screen and (max-width: 880px) {
    flex-direction: column;
    text-align: center;
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Wrapper = styled.div`
  text-align: center;
`;

export const SektionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;
export const Sektion = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 33%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  h4 {
    text-decoration: underline;
  }
  img {
    width: 100%;
    border-radius: 16px;
    filter: saturate(1.2);
    transition: 1s ease-in-out;
  }
`;

export const KontaktWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  img {
    max-height: auto;
    max-width: 60%;
    border-radius: 10%;
  }
  div {
    font-size: 1.4rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  a {
    color: white;
    text-decoration: none;
    font-size: 1.4rem;
    svg {
      padding-left: 1rem;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  @media screen and (max-width: 880px) {
    flex-direction: column;
    text-align: center;
  }
`;
